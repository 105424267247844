export const names = [
    '7thMarch',
    'Albedo',
    'Alhaitham',
    'Aloy',
    'Amber',
    'Arataki Itto',
    'Barbara',
    'Beidou',
    'Bennett',
    'Candace',
    'Castorice',
    'Chongyun',
    'Collei',
    'Cyno',
    'Dehya',
    'Diluc',
    'Diona',
    'Dori',
    'Eula',
    'Faruzan',
    'Fischl',
    'Ganyu',
    'Gorou',
    'Hu Tao',
    'Jean',
    'Kaedehara Kazuha',
    'Kaeya',
    'Kamisato Ayaka',
    'Kamisato Ayato',
    'Keqing',
    'Klee',
    'Kujou Sara',
    'Kuki Shinobu',
    'Layla',
    'Lisa',
    'Mika',
    'Mona',
    'Nahida',
    'Nilou',
    'Ningguang',
    'Noelle',
    'Qiqi',
    'Raiden Shogun',
    'Razor',
    'Rosaria',
    'Sangonomiya Kokomi',
    'Sayu',
    'Shenhe',
    'Shikanoin Heizou',
    'Sucrose',
    'Tartaglia',
    'Thoma',
    'Tighnari',
    'Traveler',
    'Venti',
    'Wanderer',
    'Xiangling',
    'Xiao',
    'Xingqiu',
    'Xinyan',
    'Yae Miko',
    'Yanfei',
    'Yaoyao',
    'Yelan',
    'Yoimiya',
    'Yun Jin',
    'Zhongli'
]

interface TDefaultIcon {
    name: string
    iconFileName: string
    path: string
}

export const iconLists = names.map<TDefaultIcon>(name => {
    const iconFileName = name.replace(/ /g, '_') + '_Icon.webp'

    return {
        name,
        iconFileName,
        path: `/icons/${iconFileName}`
    }
})

export const getIconPathFromName = (name: string) => {
    return iconLists.find(i => i.name === name)?.path
}

const autoNamePattern = /♪(.+)\./

export const getIconFileName = (requestByName: string) => {
    const result = autoNamePattern.exec(requestByName)
    if (result === null || result.length < 2 || names.indexOf(result[1]) < 0)
        return ''

    return result[1].replace(/ /g, '_')
}